import React, { useState } from 'react';
import { Link } from 'gatsby';
import Input from '../input';
import Button from '../button';

const AccountForgot = ({
  sendEmail,
  loginErrorMessage,
  partnerCode,
  isLoading = false,
}) => {
  const [forgotEmail, setForgotEmail] = useState('');

  return (
    <div className="container">
      <div
        style={{
          marginLeft: 75,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <img
          style={{ height: 41, width: 41 }}
          src="/images/herehear_logo.png"
        ></img>
        <p style={{ fontSize: 28, fontWeight: 500, paddingLeft: 10 }}>
          忘記密碼
        </p>
      </div>
      <div className="input-panel">
        <div className="message-container">
          <p className="title message">請輸入您的帳號。</p>
        </div>
        <Input
          value={forgotEmail}
          onChange={event => {
            setForgotEmail(event.target.value);
          }}
          placeholder="example@gmail.com"
          type="text"
        />
        <div className="btn-container">
          <Button
            text="送出"
            isLoading={isLoading}
            onClick={() => {
              sendEmail({
                email: forgotEmail,
              });
            }}
          />
        </div>
      </div>
      <style jsx>{`
        .container,
        .input-panel {
          width: 100%;
          min-width: 300px;
        }
        @media (min-width: 576px) {
          .container,
          .input-panel {
            width: 414px;
          }
        }
        // Medium devices (tablets, 768px and up)
        @media (min-width: 768px) {
        }

        // Large devices (desktops, 992px and up)
        @media (min-width: 992px) {
        }

        // Extra large devices (large desktops, 1200px and up)
        @media (min-width: 1200px) {
        }

        .message-container {
          margin-right: 60px;
        }
        .container {
          padding-top: 76px;
        }
        .input-panel {
          border-radius: 15px;
          top: -15px;
          padding-top: 26px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .message {
          max-width: 375px;
          font-size: 16px;
          text-align: left;
          color: #363636;
          line-height: 1.5;
          padding-bottom: 20px;
        }
        .btn-container {
          margin-bottom: 20px;
        }
        .signup-message {
          font-size: 14px;
          color: #666565;
        }
        .signup-message span {
          font-weight: 500;
          color: #6924d6;
        }
      `}</style>
    </div>
  );
};

export default AccountForgot;
