import React, { useState } from 'react';
import { navigate } from 'gatsby';
import _ from 'lodash';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import AccountForgot from '../../components/accountIuput/accountForgot';
import { getDeviceToken, getUrlParameter } from '../../util/util';

const url = process.env.GATSBY_API_URL;

const forgot = ({ location }) => {
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const sendEmail = async ({ email }) => {
    setLoginErrorMessage('');
    setIsLoading(true);
    await fetch(`${url}/api/v2/user/forgot`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    })
      .then(async result => {
        setIsLoading(false);
        const jsonResult = await result.json();
        if (result.ok) {
          const partnerCode = _.get(location, 'state.partnerCode');
          window.location.href = `/account/login?pcode=${partnerCode}`;
        } else {
          const { errorCode } = jsonResult;
          if (errorCode === 300301) {
            setLoginErrorMessage('帳號密碼錯誤');
          } else if (errorCode === 300302) {
            setLoginErrorMessage('Email 未認證');
          }
        }
      })
      .catch(error => {
        setIsLoading(false);
        // console.log('Log: error', error);
      });
  };

  return (
    <Layout
      showHamburgerBtn={false}
      showAccountBtn={false}
      showHeader={true}
      contentStyle={{
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: `url("/images/background.jpg")`,
      }}
    >
      <SEO title="登入" />
      <div>
        <AccountForgot
          sendEmail={sendEmail}
          loginErrorMessage={loginErrorMessage}
          isLoading={isLoading}
        />
        <style jsx>{`
          div {
            display: flex;
            justify-content: center;
          }
        `}</style>
      </div>
    </Layout>
  );
};

export default forgot;
